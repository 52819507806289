import React, { useEffect, useState } from 'react';
import './Footer.css';
import { isCustomFooterMenusEnabled } from '../../hooks/helpers';
import { Link, NavLink } from 'react-router-dom';
const USE_FOOTER_MENUS = isCustomFooterMenusEnabled()
const COPYRIGHT_TEXT = process.env.REACT_APP_IBL_FOOTER_COPYRIGHT
const COPYRIGHT_LINK = process.env.REACT_APP_IBL_FOOTER_COPYRIGHT_LINK
const Footer = (props) => {
  const [customMenus, setCustomMenus] = useState([])

  const handleFooterCustomMenus = () => {
    const menuInString = process.env.REACT_APP_IBL_STATIC_PAGES_ENABLED_FOOTER_MENUS;
    const firstLevelMenus = String(menuInString).split('|');
    if (firstLevelMenus.length > 0) {
      const menusList = [];
      firstLevelMenus.forEach((menus) => {
        const menu = String(menus).split(':');
        const menuLink = menu.filter((pre, index)=>index!==0).join(":")
        menusList.push({
          label: menu[0],
          link: menuLink
        });
      });
      setCustomMenus(menusList);
    }
  }

  useEffect(() => {
    USE_FOOTER_MENUS && handleFooterCustomMenus()
  }, []);
  return (
    <div className="sk-footer">
      <div className="div-block-158 footer-menu-container" style={{justifyContent:`${USE_FOOTER_MENUS ? "space-between" : "flex-start" }`}}>
        {
          process.env.REACT_APP_BOTTOM_LOGO_URL && 
          <Link
            className="skills-logo w-inline-block w--current"
            to={process.env.REACT_APP_IBL_LOGO_LINK ?? '/'}
          >
            <img
              alt=""
              className="image"
              loading="lazy"
              src={process.env.REACT_APP_BOTTOM_LOGO_URL}
              style={{
                height: process.env.REACT_APP_IBL_LOGO_HEIGHT || '57px',
              }}
            />
          </Link>
        }
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <h5>Information</h5>
          <Link to="https://public.vtoxford.org">VON Home</Link>
          <Link to="https://public.vtoxford.org/about-us">About Us</Link>
          <Link to="https://portal.vtoxford.org/collaborative">My QI Programs</Link>
          <Link to="https://vtoxford.zendesk.com/hc/en-us/categories/360002749374-Quality-Improvement-Programs">FAQ</Link>
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <h5>Terms of Service</h5>
          <Link to="https://public.vtoxford.org/privacy-statement/">Privacy Policy</Link>
          <Link to="https://cdn.mycrowdwisdom.com/von/Docs/Terms_of_Use_for_LMS_Website.pdf">Ease of Use</Link>
          <Link to="https://headless.vtoxford.org/wp-content/uploads/2019/12/VON-Logged-In-Site-EULA-November-2019.pdf">EULA</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
